import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Heading } from '@primer/react';
import { Layout } from '../../components/layout/Layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { stopConfettiInner } from '../../components/confetti/confetti';
import { VideoOverlay } from '../../basicComponents/videoOverlay/VideoOverlay';
import { colors } from './../../theme/theme';
import { TriangleDownIcon, TriangleRightIcon } from '@primer/octicons-react';
import { useGetSubscriptionStatusQuery } from '../../redux/apis/payment';
import { useAppSelector } from '../../redux/hooks';
import { selectAuth } from '../../redux/slices/auth.slice';
import { TopTipsDialog } from '../../components/infoDialogs/TopTipsDialog';
import { EditionsDialog } from '../../components/infoDialogs/EditionsDialog';
import { TopTips4EventsDialog } from '../../components/infoDialogs/TopTips4EventsDialog';
import { GroupSizesDialog } from '../../components/infoDialogs/GroupSizesDialog';
import { BombProofingDialog } from '../../components/infoDialogs/BombProofingDialog';
import { MY_GAMES_PATH } from '../../utils/constants';
import { AlldoneDialog } from '../../components/plansAndBilling/AlldoneDialog';

export const OnBoardingPage: FunctionComponent = () => {
  stopConfettiInner();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [queryParams] = useSearchParams();
  const [showNewSub, setShowNewSub] = useState('');

  useEffect(() => {
    if (queryParams.has('plan')) {
      setShowNewSub(queryParams.get('plan')!);
    }
  }, [queryParams]);

  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);
  const sub = useGetSubscriptionStatusQuery(undefined, { skip: !isLoggedIn || isGuest });

  const [expandManagingAccounts, setExpandManagingAccounts] = useState(false);
  const [expandPlayingCozyJuicyReal, setExpandPlayingCozyJuicyReal] = useState(false);
  const [expandHostingYourFirstEvent, setExpandHostingYourFirstEvent] = useState(false);

  const [howToInviteMembersVideo, setHowToInviteMembersVideo] = useState(false);
  const [howToAddMembersToYourPlanVideo, setHowToAddMembersToYourPlanVideo] = useState(false);

  const [top5Recommendations, setTop5Recommendations] = useState(false);
  const [gameRulesVideo, setGameRulesVideo] = useState(false);
  const [howToCreateGameRoom, setHowToCreateGameRoom] = useState(false);
  const [howToSetupScreen, setHowToSetupScreen] = useState(false);
  const [differentEditions, setDifferentEditions] = useState(false);

  const [topTips, setTopTips] = useState(false);
  const [createAnEvent, setCreateAnEvent] = useState(false);
  const [observeGameRooms, setObserveGameRooms] = useState(false);
  const [teamSizesAndEventLengths, setTeamSizesAndEventLengths] = useState(false);
  const [bombProofingYourEvents, setBombProofingYourEvents] = useState(false);

  return (
    <>
      <Layout>
        <Layout.Panel
          style={{ width: '100%', maxWidth: '520px', position: 'relative', marginBottom: '20px' }}
        >
          <Heading as="h1" sx={{ fontSize: 3, mb: 3, textAlign: 'center' }}>
            Welcome!
          </Heading>
          <Box marginTop="30px" maxWidth="500px" width="100%">
            We’re happy to have you here.
          </Box>
          <Box marginTop="30px" maxWidth="500px" width="100%">
            This is the <b>How To</b> page, where we’ve created some short tutorials and videos to
            help you get started. 👍
          </Box>
          <Box marginTop="30px" maxWidth="500px" width="100%">
            If you’re ready to start, go to the{' '}
            <a onClick={() => navigate(MY_GAMES_PATH)}>
              <b>
                <u>My Games</u>
              </b>
            </a>{' '}
            page.
          </Box>
        </Layout.Panel>

        {sub.data?.find(
          (s) =>
            s.isSubscriptionActive &&
            !s.isTemporaryAddOnSubscription &&
            !s.fromParent &&
            ['COMPANY', 'ENTERPRISE'].includes(s.plan)
        ) ? (
          <Layout.Panel
            style={{ width: '100%', maxWidth: '520px', position: 'relative', marginBottom: '20px' }}
          >
            <Box
              display="flex"
              flexDirection="row"
              maxWidth="500px"
              onClick={() => setExpandManagingAccounts(!expandManagingAccounts)}
              sx={{ cursor: 'pointer', height: '40px' }}
              width="100%"
            >
              {expandManagingAccounts ? (
                <TriangleDownIcon fill="#A792FF" size={40} verticalAlign="middle" />
              ) : (
                <TriangleRightIcon fill="#A792FF" size={40} verticalAlign="middle" />
              )}
              <Heading
                as="h1"
                sx={{ fontSize: 2, mt: 1, mb: 4, ml: 2, mr: 'auto', textAlign: 'left' }}
              >
                Managing account members
              </Heading>
            </Box>
            {expandManagingAccounts ? (
              <>
                <Box
                  marginTop="30px"
                  maxWidth="500px"
                  onClick={() => setHowToInviteMembersVideo(true)}
                  style={{ cursor: 'pointer' }}
                  width="100%"
                >
                  <span style={{ color: colors.accent.emphasis, marginRight: '10px' }}>◆</span>{' '}
                  Watch{' '}
                  <b>
                    <u>how to invite and remove members</u>
                  </b>{' '}
                  <i>(1-min)</i>
                </Box>
                <Box
                  marginTop="15px"
                  maxWidth="500px"
                  onClick={() => setHowToAddMembersToYourPlanVideo(true)}
                  style={{ cursor: 'pointer' }}
                  width="100%"
                >
                  <span style={{ color: colors.accent.emphasis, marginRight: '10px' }}>◆</span>{' '}
                  Watch{' '}
                  <b>
                    <u>changing your account's team size</u>
                  </b>{' '}
                  <i>(1-min)</i>
                </Box>
              </>
            ) : null}
          </Layout.Panel>
        ) : null}

        <Layout.Panel
          style={{ width: '100%', maxWidth: '520px', position: 'relative', marginBottom: '20px' }}
        >
          <Box
            display="flex"
            flexDirection="row"
            maxWidth="500px"
            onClick={() => setExpandPlayingCozyJuicyReal(!expandPlayingCozyJuicyReal)}
            sx={{ cursor: 'pointer', height: '40px' }}
            width="100%"
          >
            {expandPlayingCozyJuicyReal ? (
              <TriangleDownIcon fill="#A792FF" size={40} verticalAlign="middle" />
            ) : (
              <TriangleRightIcon fill="#A792FF" size={40} verticalAlign="middle" />
            )}
            <Heading
              as="h1"
              sx={{ fontSize: 2, mt: 1, mb: 4, ml: 2, mr: 'auto', textAlign: 'left' }}
            >
              Playing Cozy Juicy Real
            </Heading>
          </Box>
          {expandPlayingCozyJuicyReal ? (
            <>
              <Box
                marginTop="30px"
                maxWidth="500px"
                onClick={() => setTop5Recommendations(true)}
                style={{ cursor: 'pointer' }}
                width="100%"
              >
                <span style={{ color: colors.accent.emphasis, marginRight: '10px' }}>◆</span> Read
                our{' '}
                <b>
                  <u>top 3 recommendations</u>
                </b>{' '}
                <i>(1-min)</i>
              </Box>
              <Box
                marginTop="15px"
                maxWidth="500px"
                onClick={() => setGameRulesVideo(true)}
                style={{ cursor: 'pointer' }}
                width="100%"
              >
                <span style={{ color: colors.accent.emphasis, marginRight: '10px' }}>◆</span> Watch
                the{' '}
                <b>
                  <u>game rules video</u>
                </b>{' '}
                <i>(3-min)</i>
              </Box>
              <Box
                marginTop="15px"
                maxWidth="500px"
                onClick={() => setHowToCreateGameRoom(true)}
                style={{ cursor: 'pointer' }}
                width="100%"
              >
                <span style={{ color: colors.accent.emphasis, marginRight: '10px' }}>◆</span> Watch{' '}
                <b>
                  <u>how to start a new game</u>
                </b>{' '}
                <i>(2-min)</i>
              </Box>
              <Box
                marginTop="15px"
                maxWidth="500px"
                onClick={() => setHowToSetupScreen(true)}
                style={{ cursor: 'pointer' }}
                width="100%"
              >
                <span style={{ color: colors.accent.emphasis, marginRight: '10px' }}>◆</span> Watch{' '}
                <b>
                  <u>how to setup your screen</u>
                </b>{' '}
                <i>(1-min)</i>
              </Box>
              <Box
                marginTop="15px"
                maxWidth="500px"
                onClick={() => setDifferentEditions(true)}
                style={{ cursor: 'pointer' }}
                width="100%"
              >
                <span style={{ color: colors.accent.emphasis, marginRight: '10px' }}>◆</span> Read
                about the{' '}
                <b>
                  <u>different editions</u>
                </b>{' '}
                <i>(2-min)</i>
              </Box>
            </>
          ) : null}
        </Layout.Panel>

        <Layout.Panel
          style={{ width: '100%', maxWidth: '520px', position: 'relative', marginBottom: '20px' }}
        >
          <Box
            display="flex"
            flexDirection="row"
            maxWidth="500px"
            onClick={() => setExpandHostingYourFirstEvent(!expandHostingYourFirstEvent)}
            sx={{ cursor: 'pointer', height: '40px' }}
            width="100%"
          >
            {expandHostingYourFirstEvent ? (
              <TriangleDownIcon fill="#A792FF" size={40} verticalAlign="middle" />
            ) : (
              <TriangleRightIcon fill="#A792FF" size={40} verticalAlign="middle" />
            )}
            <Heading
              as="h1"
              sx={{ fontSize: 2, mt: 1, mb: 4, ml: 2, mr: 'auto', textAlign: 'left' }}
            >
              Hosting your first event
            </Heading>
          </Box>
          {expandHostingYourFirstEvent ? (
            <>
              <Box
                marginTop="30px"
                maxWidth="500px"
                onClick={() => setTopTips(true)}
                style={{ cursor: 'pointer' }}
                width="100%"
              >
                <span style={{ color: colors.accent.emphasis, marginRight: '10px' }}>◆</span> Read
                our{' '}
                <b>
                  <u>top tips</u>
                </b>{' '}
                <i>(4-min)</i>
              </Box>
              <Box
                marginTop="15px"
                maxWidth="500px"
                onClick={() => setCreateAnEvent(true)}
                style={{ cursor: 'pointer' }}
                width="100%"
              >
                <span style={{ color: colors.accent.emphasis, marginRight: '10px' }}>◆</span> Watch{' '}
                <b>
                  <u>how to start multiple games</u>
                </b>{' '}
                <i>(2-min)</i>
              </Box>
              <Box
                marginTop="15px"
                maxWidth="500px"
                onClick={() => setObserveGameRooms(true)}
                style={{ cursor: 'pointer' }}
                width="100%"
              >
                <span style={{ color: colors.accent.emphasis, marginRight: '10px' }}>◆</span> Watch{' '}
                <b>
                  <u>how to observe game rooms</u>
                </b>{' '}
                <i>(1-min)</i>
              </Box>
              <Box
                marginTop="15px"
                maxWidth="500px"
                onClick={() => setTeamSizesAndEventLengths(true)}
                style={{ cursor: 'pointer' }}
                width="100%"
              >
                <span style={{ color: colors.accent.emphasis, marginRight: '10px' }}>◆</span> Read
                about{' '}
                <b>
                  <u>group sizes and event lengths</u>
                </b>{' '}
                <i>(1-min)</i>
              </Box>
              <Box
                marginTop="15px"
                maxWidth="500px"
                onClick={() => setBombProofingYourEvents(true)}
                style={{ cursor: 'pointer' }}
                width="100%"
              >
                <span style={{ color: colors.accent.emphasis, marginRight: '10px' }}>◆</span>
                <b>
                  <u>Bomb-proofing your events</u>
                </b>{' '}
                <i>(2-min)</i>
              </Box>
            </>
          ) : null}
        </Layout.Panel>

        {howToInviteMembersVideo ? (
          <VideoOverlay
            setOpen={setHowToInviteMembersVideo}
            title="How to invite members"
            videoId={1000392161}
          />
        ) : null}

        {howToAddMembersToYourPlanVideo ? (
          <VideoOverlay
            setOpen={setHowToAddMembersToYourPlanVideo}
            title="How to add members to your plan"
            videoId={1000392245}
          />
        ) : null}

        {top5Recommendations ? <TopTipsDialog setIsOpen={setTop5Recommendations} /> : null}

        {gameRulesVideo ? (
          <VideoOverlay setOpen={setGameRulesVideo} title="Game rules" videoId={1006273486} />
        ) : null}

        {howToCreateGameRoom ? (
          <VideoOverlay
            setOpen={setHowToCreateGameRoom}
            title="How to create a game room"
            videoId={1000391765}
          />
        ) : null}

        {howToSetupScreen ? (
          <VideoOverlay
            setOpen={setHowToSetupScreen}
            title="How to setup your screen"
            videoId={1000391867}
          />
        ) : null}

        {differentEditions ? <EditionsDialog setIsOpen={setDifferentEditions} /> : null}

        {topTips ? <TopTips4EventsDialog setIsOpen={setTopTips} /> : null}

        {createAnEvent ? (
          <VideoOverlay
            setOpen={setCreateAnEvent}
            title="How to start multiple games"
            videoId={1000392000}
          />
        ) : null}

        {observeGameRooms ? (
          <VideoOverlay
            setOpen={setObserveGameRooms}
            title="How to observe game rooms"
            videoId={1000391929}
          />
        ) : null}

        {teamSizesAndEventLengths ? (
          <GroupSizesDialog setIsOpen={setTeamSizesAndEventLengths} />
        ) : null}

        {bombProofingYourEvents ? (
          <BombProofingDialog setIsOpen={setBombProofingYourEvents} />
        ) : null}
      </Layout>

      <AlldoneDialog
        open={!!showNewSub}
        planName={showNewSub}
        setOpen={(b: boolean) => setShowNewSub(b ? 'plan' : '')}
      />
    </>
  );
};
