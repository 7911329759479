import React, { useState } from 'react';
import styled from 'styled-components';
import { AddonFeature } from './AddonFeature';
import speech from '../../../images/speech.svg';
import infoIcon from '../../../images/pricingPage/infoIcon.svg'; // Ensure you have the 'i' icon image path

interface AddonCardProps {
  readonly title: string;
  readonly price?: {
    amount: string;
    currency: string;
  };
  readonly contactUs?: boolean;
  readonly features: string[];
}

const AddonCard: React.FC<AddonCardProps> = ({ title, price, contactUs, features }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleToggleTooltip = () => {
    setIsTooltipVisible((prev) => !prev);
  };

  return (
    <Card>
      <TitleWrapper>
        <CardTitle>{title}</CardTitle>
        {title === 'Add a facilitator' && ( // Only show the tooltip for 'Add a facilitator' card
          <InfoIconContainer
            onMouseEnter={() => setIsTooltipVisible(true)}
            onMouseLeave={() => setIsTooltipVisible(false)}
            onClick={handleToggleTooltip}
          >
            <InfoIcon alt="Info" src={infoIcon} />
            {isTooltipVisible && (
              <Tooltip>
                Pricing starts at $400 for up to 40 people. Contact us for pricing for larger events.
              </Tooltip>
            )}
          </InfoIconContainer>
        )}
      </TitleWrapper>
      {price ? (
        <PriceWrapper>
          <FromText>Starting at</FromText>
          <Currency>{price.currency}</Currency>
          <Amount>{price.amount}</Amount>
        </PriceWrapper>
      ) : null}
      {contactUs ? (
        <ContactUsWrapper>
          <ContactIcon src={speech} />
          <ContactText>Contact us</ContactText>
        </ContactUsWrapper>
      ) : null}
      <Divider />
      <FeatureList>
        <FeatureTitle>Add-on {contactUs ? 'includes' : 'options'}:</FeatureTitle>
        {features.map((feature, index) => (
          <AddonFeature key={index} text={feature} />
        ))}
      </FeatureList>
    </Card>
  );
};

const Tooltip = styled('div')`
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  white-space: normal;
  min-width: 240px;
  max-width: 360px;
  z-index: 1;
  opacity: 0.9;

  /* Remove or comment out this media query */
  /*
  @media (max-width: 991px) {
    left: auto;
    right: -30px;
    transform: none;
  }
  */

  @media (max-width: 480px) {
    left: auto;
    right: -100px;
    min-width: 200px;
    max-width: 280px;
  }
`;

const InfoIconContainer = styled('div')`
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
`;

const InfoIcon = styled('img')`
  width: 16px;
  height: 16px;
`;

// Wrap title and tooltip in a flex container to align them side by side
const TitleWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardTitle = styled('h3')`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
`;

const PriceWrapper = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
  white-space: nowrap;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const FromText = styled('span')`
  font-size: 17px;
  font-weight: 350;
  margin-bottom: 24px;
`;

const Card = styled('article')`
  border-radius: 20px;
  background-color: rgba(237, 233, 255, 0.6);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: visible;
  font-size: 17px;
  font-weight: 350;
  color: var(--Dark, #333);
  padding: 40px 0;
  width: 100%;
  position: relative; /* Important for tooltip positioning */
`;

const Currency = styled('span')`
  font-size: 56px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const Amount = styled('span')`
  font-size: 56px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const ContactUsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const ContactIcon = styled('img')`
  width: 70px;
  aspect-ratio: 0.98;
  object-fit: contain;
  margin-bottom: 24px;
`;

const ContactText = styled('span')`
  font-weight: 350;
  margin-bottom: 24px;
`;

const Divider = styled('hr')`
  background-color: rgba(153, 153, 153, 0.3);
  height: 1px;
  border: none;
  margin: 30px 0;
`;

const FeatureTitle = styled('li')`
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 5px;
`;

const FeatureList = styled('ul')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  padding: 0 32px;
  list-style-type: none;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

export default AddonCard;
