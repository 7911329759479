import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CJRButton } from '../../../basicComponents/cjrButton/cjrButton';
import { useWindowLocation } from '../../../hooks/useWindowLocation';
import { useGetSubscriptionStatusQuery, useCheckoutMutation } from '../../../redux/apis/payment';
import { useAppSelector } from '../../../redux/hooks';
import { selectAuth } from '../../../redux/slices/auth.slice';
import { REGISTRATION_PATH } from '../../../utils/constants';
import { ConfirmDowngradeDialog } from '../../plansAndBilling/ConfirmDowngradeDialog';
import { ConfirmUpgradeDialog } from '../../plansAndBilling/ConfirmUpgradeDialog';

interface BuyNowBtnProps {
  readonly plan: string;
}

const BuyNowBtn: React.FC<BuyNowBtnProps> = ({ plan }) => {
  const navigate = useNavigate();
  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);
  const sub = useGetSubscriptionStatusQuery(undefined);
  const { setHref } = useWindowLocation();
  const [hasPlan, setHasPlan] = useState(false);
  const [originalPlan, setOriginalPlan] = useState('');
  const [currentPlan, setCurrentPlan] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [checkout, session] = useCheckoutMutation();

  useEffect(() => {
    if (session.isSuccess) setHref(session.data.url);
  }, [session]);

  useEffect(() => {
    if (sub.isSuccess) {
      const currentPlan = sub.data!.find(
        (s) => s.isSubscriptionActive && !s.isTemporaryAddOnSubscription && !s.fromParent
      );
      setHasPlan(currentPlan?.plan === plan);
      setCurrentPlan(currentPlan?.plan ?? '');
    }
  }, [sub]);

  const onClick = () => {
    if (!sub.isSuccess) return;

    const currentPlan = sub.data!.find(
      (s) => s.isSubscriptionActive && !s.isTemporaryAddOnSubscription && !s.fromParent
    );
    setHasPlan(currentPlan?.plan === plan);
    setOriginalPlan(currentPlan?.plan ?? '');

    if (!isLoggedIn || isGuest) {
      navigate(REGISTRATION_PATH + `?plan=${plan}`);
    } else {
      if (
        !sub.data!.find(
          (s) => !s.isTemporaryAddOnSubscription && s.isSubscriptionActive && !s.fromParent
        )
      ) {
        checkout({ plan: plan });
      } else {
        setConfirm(true);
      }
    }
  };

  return (
    <>
      <CJRButton
        disabled={hasPlan}
        onClick={onClick}
        variant={hasPlan ? 'sparkDisabled' : 'sparkPrimary'}
      >
        {hasPlan
          ? 'Current plan'
          : plan == 'PRO' && currentPlan == 'COMPANY'
          ? 'Downgrade'
          : plan == 'COMPANY' && currentPlan == 'PRO'
          ? 'Upgrade'
          : 'Buy now'}
      </CJRButton>

      {plan == 'PRO' && originalPlan == 'COMPANY' ? (
        <ConfirmDowngradeDialog
          changePlan={plan}
          confirmChange={confirm}
          originalPlan={originalPlan}
          setConfirmChange={setConfirm}
        />
      ) : null}
      {plan == 'COMPANY' && originalPlan == 'PRO' ? (
        <ConfirmUpgradeDialog
          changePlan={plan}
          confirmChange={confirm}
          originalPlan={originalPlan}
          setConfirmChange={setConfirm}
        />
      ) : null}
    </>
  );
};

export default BuyNowBtn;
