export const addonData = [
  {
    title: 'One-off large events',
    price: { amount: '165', currency: '$' },
    contactUs: false,
    features: [
      'Add 5, 10, or 20 extra games',
      'Host events for 50 - 500 players',
      'Access all themed editions',
    ],
  },
  {
    title: 'Add a facilitator',
    price: { amount: '400', currency: '$' },
    contactUs: false,
    features: [
      'A skilled facilitator to host event',
      'Events for 20 - 500 players',
      'We take care of everything',
    ],
  },
  {
    title: 'Get a custom edition',
    contactUs: true,
    features: [
      'Custom to your company values',
      'All users get access to edition',
      'Created by the founders of CJR',
    ],
  },
];
