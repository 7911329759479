/*
 * Copyright (C) 2023 Rocket Bear Studio - All Rights Reserved
 * You may use and modify this code under the
 * terms of the LICENSE.md in this code repository.
 */

import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Layout } from '../layout/Layout';
import { useAppSelector } from '../../redux/hooks';
import { RoomResult, useColyseus } from '../../hooks/useColyseus';
import { deckActions } from '../../redux/slices/deck.slice';
import { getGameState } from '../../redux/slices/game.slice';
import { RoomError } from './RoomError';
import { useDispatch } from 'react-redux';
import { closeLoading, showLoading, showModal } from '../../redux/slices/modal.slice';
import { selectAuth } from '../../redux/slices/auth.slice';
import { useDeleteRoomMutation, useGetRoomDecksQuery } from '../../redux/apis/rooms';
import {
  getCurrentPlayer,
  getRoomPlayerId,
  setCurrentPlayer,
} from '../../redux/slices/player.slice';
import { LOBBY_PATH, LOGIN_PATH, MY_GAMES_PATH } from '../../utils/constants';
import { useMessageSender } from '../../hooks/useMessageSender';
import { useGetRoomStateQuery } from '../../redux/apis/colyseus';
import { useApisLoaded } from '../../hooks/useApisLoaded';
import { useUpsertPlayerMutation } from '../../redux/apis/players';

export const Room: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { roomId } = useParams();
  const [queryParams, changeQuery] = useSearchParams();

  const player = useAppSelector(getRoomPlayerId(roomId));
  dispatch(setCurrentPlayer(player?.playerId));

  const getRoomState = useGetRoomStateQuery(roomId ?? '', {
    refetchOnMountOrArgChange: true,
    skip: !roomId || roomId == ':roomId',
  });

  const [joinRoom, setJoinRoom] = useState<RoomResult>({ status: 'awaiting' });
  const [loaded, setLoaded] = useState<boolean>(false);
  const [newTabError, setNewTabError] = useState<boolean>(false);
  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);
  const { joinOrReconnect } = useColyseus();
  const getCardsQuery = useGetRoomDecksQuery(roomId!, {
    skip: !roomId || roomId == ':roomId' || !isLoggedIn,
  });
  const [deleteRoom] = useDeleteRoomMutation();
  const [upsertPlayer] = useUpsertPlayerMutation();

  useEffect(() => {
    if (getCardsQuery.isSuccess) {
      dispatch(deckActions.setDecks(getCardsQuery.data));
      dispatch(deckActions.setStatus('loaded'));
    }
  }, [getCardsQuery.isSuccess]);

  const { connectionStatus } = useAppSelector(getGameState);

  useEffect(() => {
    if (connectionStatus === 'connected') return setLoaded(true);

    const playerToAdd =
      queryParams.has('reconnectionToken') && queryParams.has('playerId')
        ? {
            playerId: queryParams.get('playerId') ?? undefined,
            reconnectionToken: queryParams.get('reconnectionToken') ?? undefined,
          }
        : player;

    if (roomId && roomId != ':roomId' && getRoomState.isSuccess) {
      if (getRoomState.data.clients.map((x) => x.sessionId).includes(playerToAdd?.playerId)) {
        // playerId already has an open session
        setNewTabError(true);
        setLoaded(false);
        return;
      }
      joinOrReconnect(roomId!, playerToAdd?.reconnectionToken).then((result) => {
        console.log(result);
        if (result.status == 'error' && result.error) {
          if ((result.error.message as string)?.includes('not found')) {
            navigate(-1);
            deleteRoom({ id: roomId });
            dispatch(showModal({ message: 'Room no longer exists', type: 'danger' }));
          }
        }
        if (
          result.status == 'joined' &&
          queryParams.has('reconnectionToken') &&
          queryParams.has('playerId')
        ) {
          console.log(result);
          navigate(LOBBY_PATH.replace(':roomId', roomId));
          dispatch(showModal({ message: 'Reconnection token invalid or expired', type: 'danger' }));
          return;
        }
        changeQuery({});
        setJoinRoom(result);
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
  }, [roomId, player, connectionStatus, getRoomState.isSuccess]);

  useEffect(() => {
    if (roomId == ':roomId') {
      //bug
      if (isGuest) return navigate(LOGIN_PATH);
      return navigate(isLoggedIn && !isGuest ? MY_GAMES_PATH : LOGIN_PATH);
    }
  }, [roomId, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && player && roomId) {
      upsertPlayer({
        roomId,
        ...player,
      });
    }
  }, [roomId, player, isLoggedIn]);

  const { playersArr } = useAppSelector(getGameState);
  const { isObserver } = useAppSelector(getCurrentPlayer);
  const prevPlayersArrRef = useRef(playersArr);
  const { removePlayer } = useMessageSender();

  useEffect(() => {
    playersArr
      .filter((p) => !p.isConnected && !p.isReady)
      .map(async (player) => {
        removePlayer({ playerId: player.id });
      });

    const prevPlayersArr = prevPlayersArrRef.current;
    prevPlayersArrRef.current = playersArr;

    if (
      prevPlayersArr.some((p) => p.isCurrentPlayer) &&
      !playersArr.some((p) => p.isCurrentPlayer) &&
      !isObserver
    ) {
      navigate(LOBBY_PATH.replace(':roomId', roomId ?? ':roomId'));
      dispatch(showModal({ message: 'You have been removed from the room', type: 'danger' }));
    }
  }, [playersArr]);

  //Manual setting of the loader for special case
  const show = useApisLoaded();
  useEffect(() => {
    if (!show && (!loaded || getCardsQuery.isLoading || getRoomState.isLoading))
      dispatch(showLoading());
    if (show && loaded && getCardsQuery.isSuccess && getRoomState.isSuccess)
      dispatch(closeLoading());
  }, [show, loaded, getCardsQuery.isLoading, getRoomState.isLoading]);

  return (
    <>
      {getCardsQuery.isError || getRoomState.isError || joinRoom.status == 'error' ? (
        <RoomError
          msg={
            joinRoom.status == 'error' && joinRoom.error.code == 4212
              ? 'Sorry! This game room is at capacity'
              : 'Error connecting to the room'
          }
        />
      ) : newTabError ? (
        <RoomError
          msg="It seems you already have a session opened for this room. Please try again. If the problem persists, try closing the browser or opening an incognito tab."
          reloadBtn
        />
      ) : !roomId ? (
        <RoomError msg="Room Id is missing" />
      ) : !isLoggedIn ? (
        <RoomError msg="Oops! You've been sent the wrong link. Ask your host or another player to copy the invite link and send it to you." />
      ) : getCardsQuery.isLoading || getRoomState.isLoading || !loaded ? (
        <Layout />
      ) : (
        <Outlet />
      )}
    </>
  );
};
